import Layout from "@components/layout/Layout/Layout"
import Team from "@partials/Team/Team"
import * as React from "react"

// markup
const TeamPage = () => (
  <Layout title="Masterbrews | Meet Our Team"
  contentClassName="px-0 max-w-full">
    <Team />
  </Layout>
)

export default TeamPage
