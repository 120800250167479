import React from "react"
import "./UserTeam.scss"

export default function UserTeam(props) {
  return (
    <div className="UserTeam h-full text-center flex flex-col">
      <div class="octagonWrap">
        <img src="/octagon-outline-shape.svg" className="octImg" alt="" />
        <img className="avatar" src={`/team/${props.team.first}.png`} alt="avatar" />
      </div>
      <div className="flex-1 flex flex-col">
        <div className="linkedinIcon flex gap-2 py-2">
          {props.team.twitter !== "#" && (
            <a href={props.team.twitter} target="_blank" rel="noreferrer">
              <img src="/team/ico_twitter.png" alt="twitter" />
            </a>
          )}
          {props.team.linkedin !== "#" && (
            <a href={props.team.linkedin} target="_blank" rel="noreferrer">
              <img src="/team/ico_in.png" alt="linked in" />
            </a>
          )}
        </div>
        <div className="flex gap-2 justify-center text-2xl font-bold">
          <p>{props.team.first}</p>
          <p>{props.team.last}</p>
        </div>
        <p className="text-lg">{props.team?.nick}</p>
        <span className="userDetail font-poppins mt-4">
          {props.team.bio}
        </span>
      </div>
      <div className="abstrac personal md:h-36 pb-4">
        <div className="pb-1">
          <span className="text text-2xl font-bold">PERSONAL</span>
        </div>
        <span className="font-poppins">
          {props.team.biofact}
        </span>
      </div>
    </div>
  )
}
