export default {
  founders: [
    {
      first: "Keith",
      last: "Bussey",
      nick: "MasterBrews-Varius",
      linkedin: "https://www.linkedin.com/in/varius/",
      twitter: "https://twitter.com/MasterBrewsNFT",
      bio: `An experienced techpreneur with over
        20 years in the online industry, Keith’s strengths
        lie in his resourcefulness, creativity and
        work ethic. As an aggressive CTO
        who delivers results, he has helped build,
        scale and guide companies in
        ecommerce (over $1B in sales), dating
        and mobile gaming - just to name a few.`,
      biofact: `
        A thrill-seeker at heart, Keith is passionate
        about Animal Welfare causes and loves his dog
        above all else. He definitely enjoys sipping
        a quality brew, or three!`,
    },
    {
      first: "Adam",
      last: "Hudani",
      nick: "Tilting-Shock",
      linkedin: "https://www.linkedin.com/in/adam-hudani/",
      twitter: "https://twitter.com/TiltingS",
      bio: `Adam has held numerous senior level
        positions such as Chief Operating Officer,
        VP of Ops and Head of Operations for
        companies across numerous verticals such
        as i-gaming, ecommerce and social gaming.
        Adam has helped numerous companies
        grow from start-up status to industry leaders
        generating over $300M in revenue. `,
      biofact: `Adam is a proud father of twins and likes to
        spend his free time going on hikes and
        catching up on the latest movie with his family.`,
    },
  ],
  council: [
    {
      first: "Nick",
      last: "Mcdonald",
      linkedin: "https://www.linkedin.com/in/nick-andrew-mcdonald/",
      twitter: "#",
      bio: `A skilled and accomplished web
        developer. Nick has held numerous senior
        developer positions with some of the largest
        companies in their respective fields such as
        PokerStars and Tesco. Nick is currently
        the Founder and CEO of Game Mechanics`,
      biofact: `Nick is an avid outdoorsman who, rain or shine,
        can often be found running, or riding his
        mountain bike around the picturesque
        Isle of Man.`,
    },
    {
      first: "Mike",
      last: "Ackerman",
      linkedin: "https://www.linkedin.com/in/ackermanmike/",
      twitter: "#",
      bio: `Michael Ackerman has been heavily involved in
        the blockchain and crypto space since its
        early days. Having successfully founded and sold
        multiple startups in the space as well as
        advised many projects from their infancy to
        product release. He was the principal
        angel investor in Masterbrews and now serves
        as the Chief Partnerships Officer.`,
      biofact: `From children's charities to animal rights
        movements, Michael's philosophy of "giving
        back more to the world than you take" has
        been center to his actions since childhood.`,
    },
    {
      first: "Steve",
      last: "Tcherchian",
      linkedin: "https://www.linkedin.com/in/stevetcherchian/",
      twitter: "#",
      bio: `A renowned security expert, Steve routinely
        conquers a complex variety of challenges
        across global, multicultural environments.
        Boasting a rare blend of visionary leadership,
        technical expertise and business savvy,
        Steve prides himself on
        “bottom line”, revenue driven results
        while adhering to his own high standards.`,
      biofact: `Steve is a devout father and family man who
        fancies himself a globe trotter, Steve
        has likely sampled beer in more countries
        than the rest of the team combined.`,
    },
    {
      first: "Timothy",
      last: "Jooste",
      linkedin: "https://www.linkedin.com/in/timothy-jooste-8594a491/",
      twitter: "https://twitter.com/TimothyJooste",
      bio: `Tim has spent most of his successful career
        building a running large Sales organizations
        for mid size to Fortune 500 companies. He has
        a personally developed 100s of leaders and
        helped recruit teams of 500+ sale people
        producing 100-200 million per year in tech sales.
        Tim specializes in cultivating an amazing work
        environment and culture as well as finding
        and developing leaders. Tim began
        his career as a door 2 door salesman and has
        risen to the top of each company he has
        worked for while also winning numerous
        national awards including the nationally
        recognized Gold Stevie Award forSales Director
        of the Year in the United States!
        `,
      biofact: `Tims passions and investment have always been
        focused in the realm of crypto and gaming
        and is now putting his almost 29 years of
        experience in building highly successful
        organizations into building Koin Games!`,
    },
  ],

  team: [
    {
      first: "Alin",
      last: "Popa",
      nick: "Dasking",
      linkedin: "https://www.linkedin.com/in/alinn-popa/",
      twitter: "#",
      bio: `Alin is a senior full-stack software engineer,
        teaching assistant, and graduate researcher.
        Passionate about computer science and
        innovation,Alin has been consistently delivering
        great software solutions.Alin is currently
        pursuing a Ph.D. in Financial Machine Learning,
        and has multiple IEEE-indexed research articles
        on cryptocurrency and neural networks.`,
      biofact: `Besides being an avid reader and an aspiring
        writer, Alin loves cooking and he's always eager
        to try recipes from all around the globe!`,
    },
    {
      first: "Jason",
      last: "Silvert",
      nick: "ThePenguinVA",
      linkedin: "https://www.linkedin.com/in/jason-silvert-6b623679",
      twitter: "https://twitter.com/ThePenguinVA",
      bio: `Better known as ThePenguinVA, Jason
        specializes incustomerrelations management
        and acts as acommunity manager and
        occasional client liaison for MasterBrews. Prior
        to MasterBrews, Jason spent a decade as
        tech support with Apple. Previously Jason spent
        10 years as a manager with Blockbuster Video.
        Jason has over 20 years of customer
        satisfaction experience.
        `,
      biofact: `1 fiancé, 1 child and 4 cats keep Jason occupied
        when he is not engaging in NFT-related duties.
        A pop culture and current events junkie,
        Jason is happy to discuss just about anything
        in the news with you.
        `,
    },
    {
      first: "Jay",
      last: "",
      nick: "Jay Ito",
      linkedin: "https://www.linkedin.com/in/jay-ito-a5a170215",
      twitter: "#",
      bio: `Jay is a talented developer with over 6 years of
        experience in blockchain development
        across multiple blockchains developing
        Dapps, Defi, Dex and marketplaces for NFTs.
        He likes to share experiences with his
        team members and inter-project
        communication.
        `,
      biofact: `Jay loves swimming and traveling
        with his family. He also likes to learn new skills
        in his free time.`,
    },
    {
      first: "Dr.BouMa",
      last: "",
      nick: "Dr.BouMa",
      linkedin: "#",
      twitter: "#",
      bio: `Dr.BouMa is a Physicist with extensive studies in
        Particle Physics and Theoretical
        Cosmology, in which he has obtained
        a MSc and PhD, respectively. His hobbies,
        which include video and board gaming and
        early alpha testing, have made
        his consultation valuable to related projects in
        the cryptocurrency space.`,
      biofact: `In his free time, Dr.BouMa enjoys video games
        and keeping up with the latest trends in
        the crypto space.
        `,
    },
    {
      first: "Medina",
      last: "",
      nick: "jpmm21",
      linkedin: "https://www.linkedin.com/in/juan-pablo-medina-mulet-90114b11a",
      twitter: "https://twitter.com/jpmm_21",
      bio: `Medina AKA jpmm21 is a young Cuban doctor
        who after fighting Covid-19 during 2021, was
        reborn as Web3 enthusiast. Medina now acts as
        Community Manager for MasterBrews.
        `,
      biofact: `Hardcore gamer and cinephile, grew up building
        PCs with scrap parts that’d he could find in the
        scarce Cuban economy. Just a young man full
        of decentralized dreams trying to
        make them happen
        `,
    },
  ],
}
