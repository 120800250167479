import React from "react"
import teamData from "@util/teamData"
import UserTeam from "@elements/UserTeam/UserTeam"
import "./Team.scss"

export default function Team() {
  return (
    <div className="Team py-24">
      <div className="mx-auto text-white px-4">
        <div className="mb-10">
          <div className="mb-10 teamHeadContainer relative flex flex-col items-center">
            <div className="font-montserrat text-4xl font-bold uppercase text-center">Co-Founders</div>
            <img src="/team/img_light.png" alt="light effect" />
          </div>
          <div className="users mx-auto">
            <div className="flex flex-wrap justify-center gap-y-24 gap-x-4">
              {teamData.founders.map((dt, idx) => (
                <div key={idx} className="userCard">
                  <UserTeam team={dt} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="my-28">
          <div className="mb-10 teamHeadContainer relative flex flex-col items-center">
            <div className="font-montserrat text-4xl font-bold uppercase text-center">Team members</div>
            <img src="/team/img_light.png" alt="light effect" />
          </div>
          <div className="users mx-auto">
            <div className="flex flex-wrap justify-center gap-y-24 gap-x-4">
              {teamData.team.map((dt, idx) => (
                <div key={idx} className="userCard">
                  <UserTeam team={dt} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="my-28">
          <div className="mb-10 teamHeadContainer relative flex flex-col items-center">
            <div className="font-montserrat text-4xl font-bold uppercase text-center">ADVISORY COUNCIL</div>
            <img src="/team/img_light.png" alt="light effect" />
          </div>
          <div className="users mx-auto">
            <div className="flex flex-wrap justify-center gap-y-24 gap-x-4">
              {teamData.council.map((dt, idx) => (
                <div key={idx} className="userCard">
                  <UserTeam team={dt} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
